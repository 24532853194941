<template>
	<router-link id="logo-header" :to="{ name: 'Home' }"
		><BaseIcon :name="icon" />
		<h1 v-if="showName">Arthur</h1></router-link
	>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
export default {
	name: 'TheLogoHeader',
	components: {
		BaseIcon,
	},
	props: {
		showName: {
			type: Boolean,
			default: false,
		},
		icon: {
			type: String,
			default: 'arthur-emblem-large',
		},
	},
}
</script>

<style lang="scss" scoped>
#logo-header {
	display: block;
	margin: 0 auto 0.3rem auto;
	text-align: center;
	width: 1.2rem;
	font-size: 0;
	line-height: 0;
}
#logo-header,
#logo-header:hover {
	color: $black;
}
#logo-header h1 {
	margin-top: 0.1rem;
}
@media (hover: hover) {
	#logo-header:hover:deep() .icn-arthur-emblem-large {
		fill: $primary;
	}
}
</style>
