<template>
	<div id="layout">
		<div>
			<TheLogoHeader :showName="true" />
			<AuthMain :isDialog="false" :landingPage="translator[$route.name]" />
		</div>
	</div>
</template>

<script>
// Components
import TheLogoHeader from '@/components/TheLogoHeader'
import AuthMain from '@/views/Auth/AuthMain'

export default {
	name: 'AuthPage',
	components: {
		TheLogoHeader,
		AuthMain,
	},
	props: {},
	setup() {},
	data() {
		return {
			// Translates between route names and page names.
			translator: {
				Login: 'login',
				Signup: 'signup',
				WaitList: 'wait-list',
			},
		}
	},
	computed: {},
	watch: {},
	created() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
#layout {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
#layout > div {
	// border: solid 1px lightblue;
	width: 2.5rem;
	min-height: 2.8rem;
}
#logo-header {
	margin-bottom: 0.5rem;
}
</style>
